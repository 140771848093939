import { createApp } from 'vue';
import { Uploader,Toast,showToast,BackTop,Picker,Popup,Lazyload,Empty   } from 'vant';
import App from './App.vue';
import { router } from './router';
import 'vant/lib/index.css';
import Compressor from 'compressorjs'
import '@vant/touch-emulator';

const app = createApp(App);
app.use(router);
app.use(Uploader);
app.use(Toast);
app.use(Popup);
app.use(BackTop );
app.use(Picker );
app.use(Lazyload );
app.use(Empty );
app.mount('#app');

